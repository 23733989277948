import React from "react"
import { Link } from "gatsby"

/* Import Global Components */
import FadeIn from "~components/animations/fadeInChildElements"
import PlusInCircleSvg from "~components/icon/normal/plusInCircle"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./links.css"

const LinksSlide = () => (
  <Slide title="links">
    <div className="links-container">
      <div>
        <FadeIn delay={325} transitionDuration={520}>
          <Link to="/works">
            Explore Work{" "}
            <span>
              <PlusInCircleSvg />
            </span>
          </Link>
          <Link to="/about">
            Learn More About P.L.{" "}
            <span>
              <PlusInCircleSvg />
            </span>
          </Link>
          <Link to="/ip">
            IP{" "}
            <span>
              <PlusInCircleSvg />
            </span>
          </Link>
          <Link to="/contact">
            Say Hello{" "}
            <span>
              <PlusInCircleSvg />
            </span>
          </Link>
        </FadeIn>
      </div>
    </div>
  </Slide>
)

export default LinksSlide
